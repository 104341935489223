import React from "react";
import { Form, Formik, ErrorMessage } from "formik";
import { Input, Button } from "antd";
import { myFirebase, AuthContext } from "../components/authProvider";
import firebase from "firebase/app";
import Countdown from "react-countdown";
import * as Yup from "yup";
import { showNotification } from "../utils";

export default function Login(props) {
  const authState = React.useContext(AuthContext);
  const [phoneSigninState, setPhoneSigninState] = React.useState("signedOut");
  const [confirmationResult, setConfirmationResult] = React.useState(null);
  const [phone, setPhone] = React.useState("");

  const { state = {} } = props.location;
  const { prevLocation } = state;
  console.log(prevLocation);

  const phoneRegExp = /^([1-9]{1})(\d{2,10})$/;
  const codeRegExp = /^(\d{1,6})$/;
  const PhoneSchema = Yup.object({
    phone: Yup.string()
      .ensure()
      .matches(phoneRegExp, "Phone number must be 10 digits, no special characters")
      .length(10, "Phone number must be exactly 10 digits")
      .required("Required")
  });
  const CodeSchema = Yup.object({
    code: Yup.string()
      .ensure()
      .matches(codeRegExp, "Code must be 6 digits, no special characters")
      .length(6, "Code must be exactly 6 digits")
      .required("Required")
  });

  const onPhoneSignIn = (values, { setStatus, setSubmitting } ) => {
    setStatus("");
    setPhoneSigninState("signingIn");
    authState.setStatus("authenticating");

    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible"
      }
    );
    const appVerifier = window.recaptchaVerifier;
    appVerifier.render().then(function(widgetId) {
      window.recaptchaWidgetId = widgetId;
    });
    myFirebase
     .auth()
     .signInWithPhoneNumber(phone, appVerifier)
     .then(function(confirmationResult) {
        console.log("Success");
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        setConfirmationResult(confirmationResult);
        setPhoneSigninState("codeSent");
        setSubmitting(false);
      })
     .catch(function(error) {
        console.log("Error:" + error.code);
        showNotification("error", "Error!", "Something went wrong. Please try again");
        setPhoneSigninState("signedOut");
      });
  };

  const onVerifyCodeSubmit = (values, { setStatus, setSubmitting, resetForm } ) => {
    setStatus("");
    confirmationResult
      .confirm(values.code)
      .then(function(result) {
        // User signed in successfully.
        console.log("redirect to", prevLocation);
        authState.setStatus("authenticated");
        setPhoneSigninState("signedIn");
        // setTimeout(() => {
          props.history.push(prevLocation);
        // }, 2000);
      })
     .catch(function(error) {
        // User couldn't sign in (bad verification code?)
        console.error("Error while checking the verification code", error);
        resetForm({values: {phone: values.phone, code: ""}});
        setStatus("Invalid verification code. Try again");
        setSubmitting(false);
      });
  };

  const onResendCode = (event) => {
    event.preventDefault();
    setConfirmationResult(null);
    const appVerifier = window.recaptchaVerifier;
    myFirebase
     .auth()
     .signInWithPhoneNumber(phone, appVerifier)
     .then(function(confirmationResult) {
        console.log("Success");
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        setConfirmationResult(confirmationResult);
        setPhoneSigninState("codeResent");
      })
     .catch(function(error) {
        console.log("Error:" + error.code);
      });
  };

  return (
    <div className="p-8 md:p-16 mx-auto">
      <div className="my-4 space-y-4">
        <Formik
          initialValues={{
            phone: "",
            code: ""
          }}
          validationSchema={(phoneSigninState === "signedOut") ? PhoneSchema : CodeSchema}
          onSubmit={(phoneSigninState === "signedOut") ? onPhoneSignIn : onVerifyCodeSubmit}
          enableReinitialize={true}
        >
          {({ values, errors, touched, handleChange, handleBlur, setFieldValue, status, isSubmitting }) => (
            <Form>
              <div className={((phoneSigninState === "signedOut") || (phoneSigninState === "signingIn")) ? "flex flex-col" : "hidden"}>
                <label className="text-gray-700 font-semibold">Phone number</label>
                <Input
                  size="large"
                  type="text"
                  name="phone"
                  className={errors.phone && touched.phone ? "border-red-500" : "border-gray-400"}
                  onChange={e => {
                    setFieldValue("phone", e.target.value, true);
                    setPhone("+91" + e.target.value);}}
                  onBlur={handleBlur}
                  value={values.phone}
                  prefix={"+91"}
                  disabled={((phoneSigninState !== "signedOut") && (phoneSigninState !== "signingIn"))}
                />
                <div
                  style={{
                    minHeight: 21
                  }}
                >
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="text-red-500"
                  />
                </div>
                <p className="text-gray-700 text-xs italic">By signing in, verification code is sent by SMS. Standard SMS rates may apply.</p>
              </div>
              <div className={((phoneSigninState === "codeSent") || (phoneSigninState === "codeResent")) ? "flex flex-col" : "hidden"}>
                <label className="text-gray-700 font-semibold">Verification code sent to {phone} </label>
                <Input
                  size="large"
                  type="text"
                  name="code"
                  className={errors.code && touched.code ? "border-red-500" : "border-gray-400"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.code}
                  disabled={((phoneSigninState !== "codeSent") && (phoneSigninState !== "codeResent"))}
                />
                <div
                  style={{
                    minHeight: 21
                  }}
                >
                  <ErrorMessage
                    name="code"
                    component="div"
                    className="text-red-500"
                  />
                </div>
                  <Countdown
                  date={Date.now() + 10000}
                  autoStart={false}
                  renderer={({seconds, completed}) => {
                    if (phoneSigninState === "codeSent") {
                      if (completed)
                        return (
                        <Button
                          id="resend-code-button"
                          type="link"
                          onClick={(event) => onResendCode(event)}
                        >
                          Resend code
                        </Button>);
                      else
                        return <span>Resend code in {seconds}</span>;
                    }
                    else
                      return null;
                  }}
                  ref={(countdown) => {
                    if (countdown) {
                      const countdownApi = countdown.getApi();
                      if (phoneSigninState === "codeSent") {
                        // countdown.setState({timeDelta: 20000});
                        countdownApi.start();
                      }
                    }
                  }}
                />
              </div>
              <div
                style={{
                  minHeight: 21
                }}
              >
                {!!status && <p className="text-red-500">{status}</p>}
              </div>
              <div id="recaptcha-container"></div>
              <div className="my-4 space-y-4 md:space-x-4 text-center">
                <Button
                  id="sign-in-button"
                  size="middle"
                  type="primary"
                  className={((phoneSigninState === "signedOut") || (phoneSigninState === "signingIn")) ? "bg-purple-700 hover:bg-purple-600 border-none h-10 md:w-64" : "hidden"}
                  shape="round"
                  block={true}
                  htmlType="submit"
                  // onClick={(event) => onSignInSubmit(event)}
                  disabled={(errors.phone && touched.phone) || !values.phone}
                  // loading={phoneSigninState === "signingIn"}
                  loading={isSubmitting}
                >
                  Sign in
                </Button>
                <Button
                  id="verify-code-button"
                  size="middle"
                  type="primary"
                  className={(phoneSigninState === "codeSent" || phoneSigninState === "codeResent") ? "bg-purple-700 hover:bg-purple-600 border-none h-10 md:w-64" : "hidden"}
                  shape="round"
                  block={true}
                  htmlType="submit"
                  disabled={(errors.code && touched.code) || !values.code}
                  loading={isSubmitting}
                >
                  Verify
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
