import * as React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Spin, BackTop } from "antd";
import moment from "moment";
import { AuthContext } from "../components/authProvider";
import { getDownloadSignedUrl } from "../utils/s3_helper";
import "../styles/certificate.css";
import IHF_logo from "../assets/CIHF_W250px.jpg";
import Qubento_logo from "../assets/QB_W250px.png";
import anonymous_photo from "../assets/profile_image.png";

export const GET_SUBJECT = gql`
query getSubject($uuid: uuid!) {
  subject(where: {uuid: {_eq: $uuid}}) {
    name
    gender
    dob
    address
    photo
    gov_id
    covidab_status
    covidag_status
    latest_vital_signs {
      created_at
      body_temperature
      oxygen_saturation
    }
    last_test_validated_at
    pass_issue_date
    labs {
      test_results(order_by: {test_date: desc}) {
        test_date
        result
        kit {
          test_type
        }
        lab_subject {
          lab {
            lab_name
            address
            certificate_no
          }
        }
      }
    }
  }
}
`;

export default function Certificate(props) {
  const authState = React.useContext(AuthContext);
  const [testData, setTestData] = React.useState([]);
  const [photo, setPhoto] = React.useState(null);

  const { loading, error, data } = useQuery(GET_SUBJECT, {
    variables: {
      uuid: props.match.params.id
    },
    onCompleted: async(data) => {
      if (!data.subject[0]) {
        props.history.push("/page-not-found");
        return;
      }
      // Redirect to status if conditions not satisfied
      if (!data.subject[0].covidab_status || 
          data.subject[0].covidag_status || 
          !data.subject[0].pass_issue_date ||
          (moment(data.subject[0].pass_issue_date).isBefore(moment(data.subject[0].last_test_validated_at)))) {
        props.history.push(`/immunity-status/${props.match.params.id}`);
        return;
      }
      if (data.subject[0].photo) {
        const photoUrl = await getDownloadSignedUrl(data.subject[0].photo, {headers: {authorization: `Bearer ${authState.token}`}});
        setPhoto(photoUrl);
      }
      let tests = [];
      data.subject[0].labs.map(lab => lab.test_results.map(t => tests.push(t)));
      // Sort in desc
      tests.sort((a,b) => b.test_date.localeCompare(a.test_date));
      console.log(tests);
      let agNegative = tests.filter(test => (test.kit.test_type === "COVIDAG" && !test.result));
      let abPositive = tests.filter(test => (test.kit.test_type === "COVIDAB" && test.result));
      setTestData([abPositive[0], agNegative[0]]);
    }
  });

  if (loading || !testData.length) return <div className="center-div-on-screen"><Spin size="large"/></div>;

  if (error) console.log(error);

  return (
    <div className="p-8 mx-auto">
      <h3 className="p-2 rounded-lg text-center text-white cert-h3">IMMUNITY PASS</h3>
      <div className="p-4 flex border border-solid border-gray-600 rounded-lg overflow-auto cert-wrapper">
        <div className="px-1 w-1\/2 text-left break-words">
          <label className="bio-labels">NAME </label>
          <br />
          <label className="value-labels">{data.subject[0].name}</label>
          <br />
          <label className="bio-labels">DATE OF BIRTH </label>
          <br />
          <label className="value-labels">{moment(data.subject[0].dob).format("DD-MM-YYYY")}</label>
          <br />
          <label className="bio-labels">GENDER </label>
          <br />
          <label className="value-labels">{data.subject[0].gender}</label>
          <br />
          <label className="bio-labels">GOVT ID </label>
          <br />
          <label className="value-labels">{data.subject[0].gov_id.id_type} No: {data.subject[0].gov_id.id_no}</label>
          <br />
          <label className="bio-labels">ANTIBODY (Ab) TEST </label>
          <br />
          <label className="sub-labels">RESULT </label>
          <br />
          <label className="value-labels">Positive </label>
          <br />
          <label className="sub-labels">DATE </label>
          <br />
          <label className="value-labels">{moment(testData[0].test_date).format("DD-MM-YYYY")}</label>
          <br />
          <label className="sub-labels">CENTRE </label>
          <br />
          <label className="value-labels">{testData[0].lab_subject.lab.lab_name}, {testData[0].lab_subject.lab.address.city}, {testData[0].lab_subject.lab.certificate_no} </label>
          <br />
          <label className="bio-labels">ANTIGEN (Ag) TEST </label>
          <br />
          <label className="sub-labels">RESULT </label>
          <br />
          <label className="value-labels">Negative </label>
          <br />
          <label className="sub-labels">DATE </label>
          <br />
          <label className="value-labels">{moment(testData[1].test_date).format("DD-MM-YYYY")} </label>
          <br />
          <label className="sub-labels">CENTRE </label>
          <br />
          <label className="value-labels">{testData[1].lab_subject.lab.lab_name}, {testData[1].lab_subject.lab.address.city}, {testData[1].lab_subject.lab.certificate_no} </label>
          <br />
          <br />
        </div>
        <div className="mx-auto w-1\/2 inline-block text-center">
          <div className="flex justify-center">
            <img
              alt="personphoto"
              src={data.subject[0].photo ? photo : anonymous_photo}
              className="w-20 md:w-32 h-auto"
            />
          </div>
          <br />
          <span className="p-1 text-white text-xs md:text-lg font-bold uppercase bg-green-500 rounded-md">Verified safe</span>
          <br />
          <label className="font-bold text-xs md:text-md text-gray-600">ISSUED ON: {moment(data.subject[0].pass_issue_date).format("DD-MM-YYYY")} </label>
          <br />
        </div>
      </div>
      <br />
      <br />
      <div className="flex p-2 justify-center">
        <img
          src={IHF_logo}
          alt="IHF logo"
          className="w-20 md:w-40 h-auto"
        />
        <img
          src={Qubento_logo}
          alt="Qubento logo"
          className="w-20 md:w-40 h-auto"
        /> 
      </div>
      <BackTop />
    </div>
  );
}