import * as React from "react";
import firebase from "firebase/app";
import "firebase/auth";

export const AuthContext = React.createContext();

// Initialize Firebase
var config = {
  apiKey: "AIzaSyDvCddpAQSeTJEmMVs3vcnNTra0QZwPJEc",
  authDomain: "capital-ihf-qb.firebaseapp.com",
  databaseURL: "https://capital-ihf-qb.firebaseio.com",
  projectId: "capital-ihf-qb",
  storageBucket: "capital-ihf-qb.appspot.com",
  messagingSenderId: "254907404993",
  appId: "1:254907404993:web:7d6b1bbb60f18f6e71fa40",
  measurementId: "G-ML38686YH8"
};

export const myFirebase = firebase.initializeApp(config);
myFirebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);

export class AuthProvider extends React.Component {
  constructor(props) {
    super(props);
    this.unsubscribeAuthListener = null;
    this.state = {
      status: "authenticated",
      user: null,
      token: null,
      role: "",
      setStatus: this.setStatus,
      fetchIdToken: this.fetchIdToken
    };
  }

  setStatus = (status) => {
    console.log("setStatus", status);
    this.setState({status: status});
  }


  fetchIdToken = async () => {
    const user = this.state.user;
    if (user) {
      const idToken = await user.getIdToken(true);
      return idToken;
    }
  }

  authoriseUser = async () => {
    myFirebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        this.setState({ user: user, token: null, role: "anonymous", status: "authorised" });
      }
      else {
        this.setState({ user: null, token: null, role: "", status: "unauthenticated" });
      }
    });
  };

  componentDidMount = () => {
    this.unsubscribeAuthListener = this.authoriseUser();
  };

  componentWillUnmount = () => {
    if (this.unsubscribeAuthListener) this.unsubscribeAuthListener();
  }

  // componentDidUpdate(prevProps, prevState) {
  //   console.log("comp", prevState, this.state);
  // }

  render() {
    return (
      <AuthContext.Provider value={this.state}>
        {console.log(this.state)}
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}
