import * as React from "react";
import { Route, Redirect } from "react-router-dom";
import { Spin } from "antd";
import { AuthContext } from "./authProvider";

export default function PrivateRoute({
  component: Component,
  path,
  history,
  ...rest
}) {
  const authContext = React.useContext(AuthContext);
  const [authStatus, setAuthStatus] = React.useState(authContext.status);

  React.useEffect(() => {
    (function() {
      setAuthStatus(authContext.status);
    })();
  }, [authContext.status]);

  // React.useEffect(() => {
  //   (function() {
  //     console.log("cleaned up");
  //   })();
  // }, []);

  console.log(authStatus);
  
  if(authStatus === "authenticated" || authStatus === "authorising") return <div className="center-div-on-screen"><Spin size="large"/></div>;
  return (
    <AuthContext.Consumer>
      {authState => 
        <Route
          path={path}
          {...rest}
          render={routeProps => 
            !!authState.user ? (
              <Component {...routeProps} />
            ) : (
              <Redirect to={{
                pathname: "/signin",
                state: {
                  prevLocation: routeProps.location.pathname
                }
              }}/>
            )
          }
        />
      }
    </AuthContext.Consumer>
  );
}
