import * as React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Spin, BackTop, Timeline } from "antd";
import moment from "moment";
import { GET_SUBJECT } from "./certificate";
import { AuthContext } from "../components/authProvider";
import { getDownloadSignedUrl } from "../utils/s3_helper";
import anonymous_photo from "../assets/profile_image.png";

const BODY_TEMPERATURE_THRESHOLD = 38;
const OXYGEN_SATURATION_THRESHOLD = 94;
export default function Status(props) {
  const authState = React.useContext(AuthContext);
  const [testData, setTestData] = React.useState([]);
  const [photo, setPhoto] = React.useState(null);

  const { loading, error, data } = useQuery(GET_SUBJECT, {
    variables: {
      uuid: props.match.params.id
    },
    onCompleted: async(data) => {
      if (!data.subject[0]) {
        props.history.push("/page-not-found");
        return;
      }
      // Redirect to immunity pass if conditions satisfied
      // if (data.subject[0].covidab_status && 
      //     !data.subject[0].covidag_status && 
      //     data.subject[0].pass_issue_date &&
      //     (moment(data.subject[0].pass_issue_date).isAfter(moment(data.subject[0].last_test_validated_at)))) {
      //   props.history.push(`/immunity-pass/${props.match.params.id}`);
      //   return;
      // }
      if (data.subject[0].photo) {
        const token = await authState.fetchIdToken();
        const photoUrl = await getDownloadSignedUrl(data.subject[0].photo, {headers: {authorization: `Bearer ${token}`}});
        setPhoto(photoUrl);
      }
      let tests = [];
      data.subject[0].labs.map(lab => lab.test_results.map(t => tests.push(t)));
      // Sort in asc
      tests.sort((a,b) => a.test_date.localeCompare(b.test_date));
      console.log(tests);
      setTestData(tests);
    }
  });

  const Status = () => {
    if (data.subject[0].covidag_status === null && 
      (data.subject[0].covidab_status === null || 
       data.subject[0].covidab_status)) {
      //Orange
      return <span className="bg-orange-500 rounded-md p-2">Not tested</span>;
    }
    else if (data.subject[0].covidag_status) {
      //Red
      return <span className="bg-red-700 rounded-md p-2">Infected</span>;
    }
    else if ((!data.subject[0].covidag_status &&
              (data.subject[0].covidab_status === null || 
              !data.subject[0].covidab_status)) ||
            (data.subject[0].covidag_status === null &&
            !data.subject[0].covidab_status)) {
      //Blue
      if (data.subject[0].latest_vital_signs.length &&
          (data.subject[0].latest_vital_signs[0].body_temperature > BODY_TEMPERATURE_THRESHOLD || 
            data.subject[0].latest_vital_signs[0].oxygen_saturation < OXYGEN_SATURATION_THRESHOLD) &&
          moment(data.subject[0].latest_vital_signs[0].created_at).isAfter(moment(data.subject[0].last_test_validated_at))) {
        return <span className="bg-orange-500 rounded-md p-2">Test required</span>;
      }
      else {
        return <span className="bg-blue-500 rounded-md p-2">Not infected</span>;
      }
    }
    else if (!data.subject[0].covidag_status &&
            data.subject[0].covidab_status) {
      //Green
      if (data.subject[0].latest_vital_signs.length &&
          (data.subject[0].latest_vital_signs[0].body_temperature > BODY_TEMPERATURE_THRESHOLD || 
            data.subject[0].latest_vital_signs[0].oxygen_saturation < OXYGEN_SATURATION_THRESHOLD) &&
          moment(data.subject[0].latest_vital_signs[0].created_at).isAfter(moment(data.subject[0].last_test_validated_at))) {
        return <span className="bg-orange-500 rounded-md p-2">Test required</span>;
      }
      else {
        return <span className="bg-green-500 rounded-md p-2">Safe</span>;
      }
    }
  };

  const TestsTimeline = () => {
    return (
      <div className="p-4 content-center m-auto">
        <Timeline mode="left" className="text-xs">
          {testData.map(test => 
            <Timeline.Item label={<label>{moment(test.test_date).format("MMMM D, YYYY")}</label>}>
              <p>{test.kit.test_type === "COVIDAB" ? "AB" : "AG"} {test.result ? "Positive" : "Negative"}</p>
              <small className="capitalize text-gray-600">{test.lab_subject.lab.lab_name}, {test.lab_subject.lab.address.city}</small>
            </Timeline.Item>)}
        </Timeline>
      </div>
    );
  };

  if (loading || (data && data.subject[0] && data.subject[0].photo && !photo)) return <div className="center-div-on-screen"><Spin size="large"/></div>;

  if (error) console.log(error);

  if (!data.subject[0]) {
    props.history.push("/page-not-found");
    return null;
  }

  return (
    <div className="p-8 md:p-16 mx-auto">
      <h2 className="text-lg md:text-xl text-center text-teal-700 mb-4">COVID-19 Status</h2>

      {/* <div className="w-11/12 mt-4 mx-auto bg-gray-300 shadow-md overflow-hidden rounded-lg"> */}
      <div className="w-11/12 mt-4 mx-auto">
        <div className="md:flex p-6">
          <img 
            className="h-16 w-16 md:h-24 md:w-24 rounded-full mx-auto md:mx-0 md:mr-6" 
            alt="patron_photo" 
            src={data.subject[0].photo ? photo : anonymous_photo}
          />
          <div className="text-center md:text-left m-auto md:ml-0">
            <h2 className="text-lg">{data.subject[0].name}</h2>
            <small className="text-gray-600">({data.subject[0].gender}, {moment().diff(moment(data.subject[0].dob), "years") ? 
                                          `${moment().diff(moment(data.subject[0].dob), "years")} years` : 
                                          `${moment().diff(moment(data.subject[0].dob), "months")} months`})</small>
            <div className="text-white text-center text-lg font-bold uppercase my-4 md:ml-0">
              <Status/>
            </div>
          </div>
        </div>
        {testData && <TestsTimeline />}
        <BackTop />
      </div>
    </div>
  );
}