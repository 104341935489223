import React from "react";
import "antd/dist/antd.css";
import { Router, Route, Switch } from "react-router-dom";
import history from "./utils/history";

import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloClient, HttpLink, InMemoryCache} from "apollo-boost";
import { onError } from "apollo-link-error";
import { setContext } from "apollo-link-context";
// import { InMemoryCache } from "apollo-cache-inmemory";

import Login from "./pages/login";
import Status from "./pages/status";
import Certificate from "./pages/certificate";
import NotFound from "./pages/pageNotFound";

import PrivateRoute from "./components/privateRoute";
import { AuthContext } from "./components/authProvider";

import { Link } from "react-router-dom";
import IHF_Logo from "./assets/IHF_Logo_TransBG.png";
import bg_image from "./assets/bg_image.jpg";
import Qubento_logo from "./assets/QB_Logo_BW.png";
import "./styles/index.css";


export const DB_DOMAIN = "https://dbdroplet.indiahealth.com";

function App() {
  const authContext = React.useContext(AuthContext);
  const httpLink = new HttpLink({
    uri: `${DB_DOMAIN}/v1/graphql`,
  });

  const authLink = setContext((_, { headers }) => {
    const token = authContext.token;
    const header = {...headers};
    if (token)
      header.authorization = `Bearer ${token}`;
    return {
      headers: header
    };
  });

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      console.log("Error");
      console.log("graphQLError", graphQLErrors);
      console.log("networkError", networkError);
      if (graphQLErrors)
        graphQLErrors.map(async ({ message, extensions }) => {
          switch (extensions.code) {
            case "data-exception":
              break;
            case "invalid-headers":
              console.log("invalid-headers");
              window.location.href = "/signin";
              break;
            case "validation-failed":
              // props.history.push("/something-went-wrong");
              break;
            case "invalid-jwt":
              console.log("invalid-jwt");
              // Refresh token
              await authContext.fetchIdToken(true);
              window.location.href = "/signin";
              break;
            default:
              // default case
              console.log(extensions.code);
          }
        });
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
        // props.history.push("/network-error");
      }
    }
  );

  const client = new ApolloClient({
    link: errorLink.concat(authLink.concat(httpLink)),
    cache: new InMemoryCache()
  });

  return (
    <ApolloProvider client={client}>
      <Router history={history}>
        <div 
          style= {{ backgroundImage: `url(${bg_image})` }} 
          className="min-h-screen bg-fixed bg-cover md:bg-contain md:bg-repeat"
        >
          <div
            style={{
              maxWidth: "1600px",
              minHeight: "calc(100vh - 200px)"
            }}
            className="px-4 flex mx-auto"
          >
            <div className="w-full md:w-3/4 lg:w-1/2 pb-16 text-md shadow-3xl overflow-hidden mt-0 mx-auto rounded-b-lg bg-gradient-to-b from-white via-gray-100 to-transparent">
              <div className="py-4 px-6 md:px-16 flex justify-center">
                <Link to="/signin">
                <div className="space-x-1 -ml-2">
                  <div className="inline-block align-middle">
                      <img src={IHF_Logo} alt="logo" className="w-8 md:w-16" />
                  </div>
                  <div className="inline-block align-middle font-bold text-blue-800 hover:text-teal-600 text-xl md:text-2xl">
                      Capital IHF
                  </div>
                </div>
                </Link>
              </div>
              <Switch> 
                <Route 
                  path="/signin"
                  exact
                  component={Login}
                  history={history}
                />
                <PrivateRoute
                  path="/immunity-status/:id"
                  exact
                  component={Status}
                  history={history}
                />
                <PrivateRoute
                  path="/immunity-pass/:id"
                  exact
                  component={Certificate}
                  history={history}
                />
                <Route path="*" component={NotFound} />
              </Switch>
            </div>
          </div>
          <div className="p-2 justify-center">
            <img
              src={Qubento_logo}
              alt="Qubento logo"
              className="p-1 m-auto"
            />
            <p className="text-white text-md text-center">Powered by Qubento</p>
          </div>
        </div>
      </Router>
    </ApolloProvider>
  );
}

export default App;
